/*
 * @Author: web.王佳豪
 * @Date: 2020-11-10 14:13:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-15 14:00:24
 * @Description: 路由
 * 每次添加新的二级菜单 都需要往 \src\assets\js\route.js  这个路径中添加相对应的内容  - 快捷菜单栏中需要使用
 */
import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);


const index = [
  {
    path: '/',
    component: () => import('@/view/agreement.vue'),
    name: 'Agreement'
  },
  {
    path: '/introduce',
    component: () => import('@/view/introduce.vue'),
    name: 'introduce'
  },
  {
    path: '/privacyPolicy',
    component: () => import('@/view/privacyPolicy.vue'),
    name: 'privacyPolicy'
  },

]






const constantRouterMap = [
  ...index,
]
// 配置路由
const route = new Router({
  // mode: 'history',  //去掉url中的#*/
  routes: constantRouterMap,
})


export default route
